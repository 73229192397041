
const common = {
    /**
 * アクセストークンを付与したURLを返す
 * @param url APIのURL
 * @returns アクセストークンを付与したURL
 */
    addAccessToken: (url: string | undefined) => {
        if (url === undefined) return '';
        const accessToken = sessionStorage.getItem('accessToken');
        console.log(`${url}/${accessToken}`);
        return `${url}/${accessToken}`;
    },
    isHiragana: (str: string) => {
        return str.match(/^[ぁ-んー　]*$/);
    },
    isKatakana: (str: string) => {
        return str.match(/^[ァ-ヶー　]*$/);
    },
    /**
 * 小数点の桁数を返す
 * @param url APIのURL
 * @returns アクセストークンを付与したURL
 */
    getDecimalPointLength: (num: number) => {
        return num.toString().split('.')[1]?.length || 0;
    },

    /**
     * 小数点を削除して返す
     */
    removeDecimalPoint: (num: number) => {
        //数字以外の文字を取得（単位部分）
        const unit = num.toString().replace(/[0-9.]/g, '');
        //数字のみを取得
        num = Number(num.toString().replace(/[^0-9.]/g, ''));
        //小数点が.00の場合は小数点を削除
        if (num % 1 === 0) {
            num =  Math.floor(num); 
        }
        return num + unit;
    }
}


export default common;