import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
    // ここに必要なスタイルを追加
    @media (max-width: 900px) {
        rt{
            font-size: 5px;
        }
        
    }
`;

const FuriganaText = ({ text }: { text: string }) => {
    const convertFurigana = (str : string) => {
        const regex = /([\u3400-\u9FBF]+)\{(.+?)\}/g;
        const convertedText = str.replace(regex, '<ruby>$1<rt>$2</rt></ruby>');
        return convertedText;
    };

    return <StyledSpan dangerouslySetInnerHTML={{ __html: convertFurigana(text) }} />;
};

export default function RubyText({ text }: { text: string }) {
    return <FuriganaText text={text} />;
}

