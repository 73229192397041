import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../../components/RubyText.tsx";
import Modal from "../../../components/Modal.tsx";
import Button from "../../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../../common/common.ts';
import Message from "../../../components/Message.tsx";
import { UseCode } from "../../../providers/CodeProvider.tsx";
import { UseUser, UseUserRefresher } from "../../../providers/UserProvider.tsx";
import { di } from "@fullcalendar/core/internal-common";
import Accordion from "../../../components/Accordion.tsx";
const Info = styled.p`
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;
const GradeHeader = styled.div`
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1em;
    h1{
        font-size: 2em;
        margin-left: 0.5em;
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
    @media (max-width: 900px) {
        margin-top: 0.5em;
    }
`;

const GradeSwitcher = styled.div`
    width: 2em;
    height: 2em;    
    &.prev{
        background-image: url('./img/button-prev.png');
        background-size: 100%;
        background-repeat: no-repeat;

    }
    &.next{
        background-image: url('./img/button-next.png');
        background-size: contain;
        background-repeat: no-repeat;
        
    }
    &.disabled{
        //background-imageをグレーアウトにする
        filter: grayscale(100%);
    }
    @media (max-width: 900px) {
        width: 1em;
        height: 1em; 
    }
`;

const RankingResult = styled.div`
    width: 95%;
    height: calc(100% - 6em);
    margin-top: 1em;
    overflow-y: scroll;
    @media (max-width: 900px) {
        margin-top: 0.5em;
    }
`;
const RankingHeader = styled.div`
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #28c726;
    height: 3em;
    border: 2px solid #000000;
    margin-top: 1em;
    h2{
        margin-left: 0.5em;
        text-align:left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
    p{
        @media (max-width: 900px) {
            font-size: 0.5em;
        }
    }
    button{
        min-width:fit-content;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    @media (max-width: 900px) {
        margin-top: 0.5em;
    }

`;
const RankingBody = styled.div`
    width: 99%;
    background-color: #fff;
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    @media (max-width: 900px) {
            font-size: 0.5em;
        }
`;
const RankInfo = styled.div`
    width: 33.33%;
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dotted #000000;
    &.myrank{
        background-color: #ff959c;
    }
`;

const Rank = styled.div`
    width: 3em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffb95;
    border-right: 1px solid #000000;
    .my-rank{
        background-color: #ff959c;
    }
`;

const Count = styled.div`
    width: 6em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px dotted #000000;
`;

const UserName = styled.div`
    width: calc(100% - 9em);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #000000;
`;

function minutesToTime(minutes: number): { hours: number, minutes: number, seconds: number } {
    //数字のみを取得
    minutes = Number(minutes.toString().replace(/[^0-9.]/g, ''));
    // 分を時と分に分割
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    // 残りの分を秒に変換
    const seconds = Math.round((minutes - hours * 60 - mins) * 60);
    return { hours, minutes: mins, seconds };
}

const Ranking = () => {
    //現在月の取得
    const date = new Date();
    const [rankings, setRankings] = useState([]);
    const [displayGrade, setDisplayGrade] = useState(0);
    const [displayMonth, setDisplayMonth] = useState(date.getMonth() + 1);

    //コードの取得
    const codes = UseCode();
    //ユーザー情報の取得
    const user = UseUser();

    /**
     * 記録情報の取得
     */
    const getRankings = async (grade: number , month: number) => {
        //学年の加算処理
        let queryGrade = displayGrade + grade;
        if (queryGrade <= 0 || queryGrade > 6) {
            return false;
        }
        //学年の変更があったか
        if(displayGrade != queryGrade){
            setDisplayGrade(queryGrade);
        }
        //月の加算処理
        let queryMonth = displayMonth + month;
        if (queryMonth <= 0 || queryMonth > 12) {
            return false;
        }
        //月の変更があったか
        if(displayMonth != queryMonth){
            setDisplayMonth(queryMonth);
        }
        
        setRankings([]);
        let query = {
            'grade': queryGrade,
            'month': queryMonth
        };
        console.log(query);
        const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_RECORD_RANKING_GET), { params: query });
        if (response) {
            setRankings(response.data.data);
        }
    }

    useEffect(() => {
        if (user) {
            getRankings(user.grade_id , displayMonth);
        }
    }, []);

    return (
        <>
            {
                displayGrade == 0 &&
                <p>読み込み中...</p>
            }
            {
                displayGrade > 0 &&
                <>
                    <GradeHeader>
                        <GradeSwitcher onClick={() => { getRankings(-1 , 0) }} className={displayGrade <= 1 ? 'prev disabled' : 'prev'}></GradeSwitcher>
                        <h1>{displayGrade}年生</h1>
                        <GradeSwitcher onClick={() => { getRankings(1 , 0) }} className={displayGrade >= user.grade_id ? 'next disabled' : 'next'}></GradeSwitcher>
                    </GradeHeader>
                    <GradeHeader>
                        <GradeSwitcher onClick={() => { getRankings(0 , -1) }} className={displayMonth <= 0 ? 'prev disabled' : 'prev'}></GradeSwitcher>
                        <h1>{displayMonth}月</h1>
                        <GradeSwitcher onClick={() => { getRankings(0 , 1) }} className={displayMonth > 12 ? 'next disabled' : 'next'}></GradeSwitcher>
                    </GradeHeader>

                    <Info><RubyText text="種目{しゅもく}をタップするとランキングが表示{ひょうじ}されます" /></Info>
                    <RankingResult>
                        {
                            rankings.length == 0 &&
                            <>
                                <p>読み込み中...</p>
                            </>
                        }
                        {
                            rankings && Object.entries(rankings).map(([key, value], index) => {
                                if (value['ranking'].length > 0) {
                                    return (
                                        <>
                                            <Accordion title={
                                                <RankingHeader>
                                                    <h2><RubyText text={key} /></h2>
                                                    <p>あなたの順位：{value['rank'] > 0 ? `${value['rank']}位` : value['rank']}</p>
                                                    {/*<Button.Default className="warning small"><RubyText text="この種目{しゅもく}の説明{せつめい}"></RubyText></Button.Default>*/}
                                                </RankingHeader>
                                            }>
                                                <RankingBody>
                                                    {
                                                        Object.entries(value['ranking']).map((item, index) => {
                                                            return (
                                                                <RankInfo key={index} className={value['rank'] == (index + 1) ? 'myrank' : ''}>
                                                                    <Rank>{index + 1}</Rank>
                                                                    {
                                                                        item[1].score_unit == '時分秒' ?
                                                                        <Count>
                                                                            <RubyText text={`${minutesToTime(item[1].score).hours}時間{じかん}${minutesToTime(item[1].score).minutes}分{ふん}${minutesToTime(item[1].score).seconds}秒{びょう}`} />
                                                                        </Count>
                                                                        :
                                                                        <Count>{item[1].score}{item[1].score_unit}</Count>
                                                                    }
                                                                    <UserName>{item[1].nickname}</UserName>
                                                                </RankInfo>
                                                            )
                                                        })
                                                    }
                                                </RankingBody>
                                            </Accordion>
                                        </>
                                    )
                                }
                            })
                        }

                    </RankingResult>
                </>
            }

        </>
    );
}
export default Ranking;