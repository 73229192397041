import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Character from "../../components/Character.tsx";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import Button from "../../components/Button.tsx";
import { useCompetition } from "../../providers/CompetitionProvider.tsx";
import { useCourse } from "../../providers/CourseProvider.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Competition, defaultCompetition } from "../../models/Competition.ts";
import axios from "axios";
import RubyText from "../../components/RubyText.tsx";
import Modal from "../../components/Modal.tsx";

const Container = styled.div`
    display: flex;
    /* margin: 50px 50px 10px 50px; */
    height: 70vh;
    width: 90%;
`;

const CompetitionArea = styled.main`
    display: flex;
    gap: 100px;
    width:75%;
    background-color: rgba(255,255,255,0.7);
    border-radius:10px;
    border: 3px solid white;
    padding:20px;
    box-sizing: border-box;
    text-align: left;
    @media (max-width: 900px) {
        gap: 30px;
        width:100%;
    }
`;

const AreaTitle = styled.h6`
    margin: 0 0 20px 0;
    @media (max-width: 900px) {
        margin: 0 0 0 0;
    }
`;
const Menu = styled.div`
    width:100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //div要素を横並びにする
    & > div.left{
        width:45%;
        text-align: -webkit-left;
    }
    & > div.right{
        width:55%;
        text-align: -webkit-right;
    }
`;
const FlexChild = styled.div`
    width: 50%;
    display:flex;
    flex-direction: column;
    label{
        @media (max-width: 900px) {
        font-size: 0.5em;
        }
    }
    .selectCourse{
        display: "flex"; 
        margin-bottom: 20px;
        align-items: center;
        @media (max-width: 900px) {
            display: block;
            margin-bottom: 5px;
        }
    }
`;
const CourseSelect = styled.select`
    appearance: none;
    width: 14em;
    height: calc(1rem + 20px);
    padding-left: 10px;
    border-radius: 5px;
    font-size: larger;
    &child{
        content:'';
        width: 100px;
        height: 100px;
        background: black;
    }
    @media (max-width: 900px) {
        width: 100%;
        height:1.5em;
        font-size: small;
        padding: 1px;
    }
`;
const RankingOption = styled.option`
    &.before{
            content: "";
            width: 1em;
            height: 1em;
            background-color: green;
        }
`;
const ListItem = styled.li`
    list-style: none;
    padding: 10px 0 10px 10px;
    margin-right: 10px;
    border-bottom: 1px solid black;
    //activeクラスが付いたら背景色を変更
    &.active{
        background-color: #ffed00;
    }
    @media (max-width: 900px) {
        font-size: 0.5em;
        padding: 5px 0 5px 5px;
    }
`;
const CompetitionTitle = styled.h4`
    border-bottom: 1px solid black;
    text-align: left;
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;
const CompetitionBody = styled.p`
    text-align: left;
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;
const CharacterArea = styled.div`
    width:25%;
`;

const CourseSelectTitle = styled.label`
    margin-right: 20px;
    white-space: nowrap;
`;

const CompetitionContainer = styled.div`
    border: 1px solid black;
    overflow: scroll;
    padding-right: 10px;
    height: 100%;
`;
const CompetitionOL = styled.ol`
    height: 100%;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    &::-webkit-scrollbar{
        width: 10px;
        margin-right: 10px;
        display:block;
    }
    &::-webkit-scrollbar-thumb{
        background-color: green;
        border-radius: 5px;
    }
`;

// const VideoFrame = styled.iframe`
//     width: 90% !important;
//     height: 80%;
//     margin: auto;
//     margin-top: 10px;
//     display: block;
// `;
const VideoFrame = styled.video`
    width: 90% !important;
    height: 80%;
    margin: auto;
    margin-top: 10px;
    display: block;
`;
const Courses = [
    { ID: 'ranking', Name: 'ランキングコース' },
    { ID: 'enjoy', Name: 'エンジョイコース' },
    { ID: 'mypace', Name: 'マイペースコース' },
    { ID: 'hapihapi', Name: 'みんなではぴはぴコース' },
];

const hasAmbition = async (competition: Competition) => {

    //envファイルからAPIのURLを取得
    let apiUrl = process.env.REACT_APP_API_URL || '';

    //APIから目標値取得
    return axios.get(`${apiUrl}/ambition/${sessionStorage.getItem('accessToken')}?competition_id=${competition.competition_id}`)
        .then((res) => {

            //目標値が存在するか
            const hasAmbitiousScore = Object.keys(res.data.data).indexOf('ambitions_score') > 0;

            return hasAmbitiousScore;
        })
        .catch(error => {
            console.log(error.request.response);

            return false;
        });
}

export const SelectCompetition = () => {

    const location = useLocation();
    const urlParams = location.state as { courseID: string };
    const navigate = useNavigate();

    const { competitions } = useCompetition();
    const { courseID, setCourseID } = useCourse();
    const [competition, setCompetition] = useState<Competition>(defaultCompetition);

    //初期表示
    useEffect(() => {

        //コースIDの設定
        setCourseID(urlParams.courseID);

        const courseSelector = document.getElementById('courseSelect') as HTMLSelectElement;
        courseSelector.value = urlParams.courseID;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //画面表示する種目の一覧
    const [targets, setTargets] = useState<Competition[]>();

    //表示対象種目抽出処理
    const setTargetCompetitions = () => {

        //未取得時は処理終了
        if (competitions.size === 0) return;
        if (courseID === "") return;

        //コース名がプロパティ扱いなため、Mapに変換する
        const castedCompetitions = new Map(Object.entries(competitions));

        //対象のコースから種目一覧を取得する
        //Map({index:Competition})形式のため、valueのみ拾い集める
        const list = Object.values(castedCompetitions.get(courseID ?? urlParams.courseID) ?? new Map()) as Competition[];

        //表示種目設定
        setTargets([...list]);
    }

    //全種目一覧の取得時やコース変更時、表示種目の一覧を抽出、設定する
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(setTargetCompetitions, [courseID, competitions]);

    const clickConfirm = async () => {

        if (competition.competition_id === 0) return;

        let showSetAmbition: boolean = false;

        //マイペースコースの場合は、目標値が設定されてるかを確認
        if (competition.course_id === 3) {

            //目標値の有無で、目標入力画面の表示/非表示を切り替える
            const ambitionRegistered = await hasAmbition(competition);
            showSetAmbition = !ambitionRegistered;
        }

        //実績入力画面へ遷移
        navigate(`input`, {
            state: {
                showSetAmbition: showSetAmbition,
                competition: competition
            }
        });
    };

    const [showModal, setShowModal] = useState<boolean>(false);


    return (
        <>
            <h1><RubyText text="種目{しゅもく}を選択{せんたく}する" /></h1>
            <Container>
                <CharacterArea>
                    <Character />
                </CharacterArea>
                <CompetitionArea>
                    <FlexChild>
                        <AreaTitle><RubyText text="コースと種目{しゅもく}を選択{せんたく}してください。" /></AreaTitle>
                        <div className="selectCouese">
                            <CourseSelectTitle><RubyText text="コース選択{せんたく}" /></CourseSelectTitle>
                            <CourseSelect id="courseSelect" onChange={(c) => setCourseID((c.target as HTMLSelectElement).value)}>
                                {Courses.map(c => <RankingOption key={c.ID} value={c.ID}>{c.Name}</RankingOption>)}
                            </CourseSelect>
                        </div>

                        <label><RubyText text="種目{しゅもく}" /></label>
                        <CompetitionContainer>
                            <CompetitionOL>
                                {targets?.map(c =>
                                    <ListItem
                                        key={c.competition_id}
                                        onClick={() => setCompetition(c)}
                                        className={competition.competition_id === c.competition_id ? 'active' : ''}>
                                        <RubyText text={c.competition_name} />
                                    </ListItem>
                                )}
                            </CompetitionOL>
                        </CompetitionContainer>
                    </FlexChild>
                    <FlexChild>
                        <div style={{
                            background: 'url(/img/movie_thumbnail.png)',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: "100%",
                            height: "30%",
                        }}
                            onClick={() => setShowModal(true)}>
                        </div>

                        <div style={{"overflowY":"scroll"}}>
                            <CompetitionTitle><RubyText text={competition.competition_name} /></CompetitionTitle>
                            <CompetitionBody>
                                {
                                competition.competition_description.split(/(\n)/).map((item, index) => {
                                    if(item === "\n"){
                                        return <br />
                                    }else{
                                        return (
                                            <RubyText text={item} />
                                        )
                                    }
                                })
                                }
                            </CompetitionBody>
                        </div>
                    </FlexChild>
                </CompetitionArea>

            </Container >
            <Menu>
                <div className="right"><Button.Back /></div>
                <div className="left"><ConfirmButton onClick={clickConfirm} /></div>
            </Menu>
            {
                showModal &&
                <Modal className="pos-absolute">
                    {
                        competition.link ?
                            <VideoFrame src={`${competition.link}`} controls autoPlay muted style={{ width: '272px' }} /> :
                            <div style={{ height: "50%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <span style={{ color: "red" }}><RubyText text={"この種目{しゅもく}には、動画{どうが}が登録{とうろく}されていません"} /></span>
                            </div>
                    }
                    <Button.Default onClick={() => setShowModal(false)}>とじる</Button.Default>
                </Modal>
            }
        </>
    )
}