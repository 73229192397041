import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"
import styled from "styled-components";
import Confetti from 'react-confetti'
/**
 * aaa
 */
const ModalDiv = styled.div`
    height:75vh;
    width:65vw;
    text-align: -webkit-center;
    position: fixed;
    //中央寄せ
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    border: 3px solid #000000;
    padding: 10px;
    padding-bottom:0px;
    overflow: scroll;
    z-index: 9999;
    &::-webkit-scrollbar{
        width: 10px;
        margin-right: 10px;
        display:block;
    }
    &::-webkit-scrollbar-thumb{
        background-color: green;
        border-radius: 5px;
    }
    //フェードインアニメーション
    animation: fadein 0.5s;
    //フェードインアニメーション
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    //h2タグのスタイル
    h2 {
        margin-top: 0;
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
    
    &.bg-green{
        background: #72ff70dc;
        border: 3px solid #ffffff;
    }
    &.half{
        width: 50vw;
    }
    &.fit{
        width: fit-content;
        height: fit-content;
        padding: 3rem 5rem 3rem 5rem;
    }
    &.Confetti{
        width: 100vw;
        height: 100vh;
        background: #63636384;
    }
    &.pos-absolute{
        position: absolute;
    }
`; 

/**
 * モーダルコンポーネント
 * @returns 
 */
export default function Modal({ children, className }: { children: React.ReactNode, className?: string }) {
    // 背景画像の設定
    return (
        <ModalDiv className={className}>
            {
                //classNameにConfettiが含まれている場合、背景画像を設定
                className?.includes("Confetti") && (
                    <Confetti recycle={true}/>
                )
            }
            {children}
        </ModalDiv>
    );
}
