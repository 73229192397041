import styled from "styled-components";

export const LoginContainer = styled.div`
    height:90vh;
    width:100vw;
    background-image: url('./img/login.png');
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: block;
    text-align: -webkit-center;
    padding-top: 10vh;
    overflow: hidden;
`;