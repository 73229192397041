import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
    from {
        height: 0%;
    }
    to {
        height: 60%;
    }
`;

const slideOut = keyframes`
    from {
        height: 60%;
    }
    to {
        height: 0%;
    }
`;

const Board = styled.div<{ $Visible: boolean }>`
    animation: ${props => props.$Visible ? slideIn : slideOut} .5s ease-in-out;
    position: absolute;
    bottom: 20px;
    left: 25%;
    height: ${props => props.$Visible ? 'calc(60% - 20px)' : '0%'};
    width: 50% !important;
    z-index: 99999;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface ButtonProps {
    className?: string,
    children?: React.ReactElement,
}


const Row = styled.div`
        display: flex;
        justify-content: center;    
    `;

const Button = styled.div<ButtonProps>`
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    width: 3em;
    height: 3em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    &:hover{
        background-color: #eed600;
    }
`;

const KeyBoardType = {
    Integer: "integer",
    Float: "float"
}
const Container = styled.div`
    `;

const NumberKeyBoard = (props: {
    InitialValue?: string,
    OnClickOK: () => void,
    OnSetText: React.Dispatch<React.SetStateAction<string>>,
    Type?: string
}) => {

    const [text, setText] = useState<string>(props.InitialValue ?? '');

    const ButtonEvent = (event: React.MouseEvent) => {

        const target = event.target as HTMLElement;

        const newValue = text + target.textContent;

        const type = (!props.Type) ?
            KeyBoardType.Integer :
            props.Type;

        switch (type) {
            case KeyBoardType.Integer:
                // 0が2回連続はNG
                if (newValue === '00') return;
                // 先頭が[0]の場合はNG
                if (Number.isNaN(Number.parseInt(newValue))) return;
                // 10000以上はNG
                if (10000 <= Number.parseInt(newValue)) return;
                setText(String(Number.parseInt(newValue)));

                break;
            case KeyBoardType.Float:

                // 先頭が[.]はNG
                if (newValue === '.') return;

                // 0が2回連続はNG
                if (newValue === '00') return;

                // 文字列中の[.]の数
                const dotCount = (newValue.match(/\./g) || []).length;

                // [.]が2つ以上あったらNG
                if (dotCount > 1) return;

                // [.]が最後の場合は見逃す
                const hasDot = dotCount > 0;
                if (hasDot && newValue.lastIndexOf('.') === newValue.length - 1) {
                    setText(newValue);
                    return;
                }

                // 小数点以下は2桁まで
                if (hasDot && newValue.split('.')[1].length > 2) return;
                
                if (Number.isNaN(Number.parseFloat(newValue))) return;
                // 10000以上はNG
                if (10000 <= Number.parseFloat(newValue)) return;

                setText(newValue);
                break;
        }

        event.stopPropagation();
    }

    const clickDelete = () => {
        setText(text.slice(0, -1));
    }

    useEffect(() => {
        props.OnSetText(text);
    }, [text]);

    return (
        <Container>
            <div style={{
                width: "100%",
                height: "3em",
                display: "inline-flex",
                justifyContent: "end",
                alignItems: "center",
                background: "white",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "10px",
                textAlign: "right",
            }}><span style={{ paddingRight: "10px" }}>{text}</span></div>
            <Row>
                <Button onClick={ButtonEvent}><p>1</p></Button>
                <Button onClick={ButtonEvent}><p>2</p></Button>
                <Button onClick={ButtonEvent}><p>3</p></Button>
            </Row>
            <Row>
                <Button onClick={ButtonEvent}><p>4</p></Button>
                <Button onClick={ButtonEvent}><p>5</p></Button>
                <Button onClick={ButtonEvent}><p>6</p></Button>
            </Row>
            <Row>
                <Button onClick={ButtonEvent}><p>7</p></Button>
                <Button onClick={ButtonEvent}><p>8</p></Button>
                <Button onClick={ButtonEvent}><p>9</p></Button>
            </Row>
            <Row>
                <Button onClick={ButtonEvent}><p>0</p></Button>
                <Button onClick={ButtonEvent}><p>.</p></Button>
                <Button onClick={props.OnClickOK}><p>OK</p></Button>
            </Row>
            <Row>
                <Button onClick={clickDelete}><p>×</p></Button>
            </Row>
        </Container>
    )
}

NumberKeyBoard.defaultProps = {

}
export const KeyBoard = (props: {
    InitialValue?: string,
    OnClose: () => void,
    OnSetText: React.Dispatch<React.SetStateAction<string>>,
    Type?: string,
}) => {

    const [visible, setVisible] = useState<boolean>(true);

    const removeEvent = (e: MouseEvent) => {

        const element = e.target as HTMLElement;

        if (!element.closest('#soft_keyboard')) {
            setVisible(false);
            setTimeout(() => props.OnClose(), 500);
        }
    }

    const [width, setWidth] = useState<number>(window.innerWidth);
    const widthEvent = (e: Event) => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {

        //DOMが追加されたタイミングで、イベントの追加
        const timeoutId = setTimeout(() => {
            document.addEventListener('click', removeEvent);
            window.addEventListener('resize', widthEvent);
        }, 0);

        //DOM削除のタイミングで、追加したイベントの削除
        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener('click', removeEvent);
            window.removeEventListener('resize', widthEvent);
        }
    }, []);

    return (
        <Board id="soft_keyboard" $Visible={visible}>
            <NumberKeyBoard
                InitialValue={props.InitialValue ?? ''}
                Type={props.Type}
                OnSetText={props.OnSetText}
                OnClickOK={() => { document.getElementsByTagName('body')[0].click(); }} />
        </Board >
    )
}