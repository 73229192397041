import styled from "styled-components";

const Message = {
    Success: styled.div`
        width: 70%;
        padding: 10px;
        background-color: #a0ffa0;
        color:green;
        border: 2px solid green;
        border-radius: 5px;
        margin:0 0 10px 10px;
    `,
    Error: styled.div`
        width: 80%;
        padding: 10px;
        background-color: #ffc2d1;
        color:#fc0000;
        border: 2px solid #fc0000;
        border-radius: 5px;
        margin:0 0 10px 10px;
    `,
    ErrorLabel: styled.label`
        color:#fc0000;
        font-size: 0.8em;
    `
};

export default Message